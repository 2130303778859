import React from 'react'
import { SvgIcon } from '@material-ui/core'

const InstagramIcon = props => (
  <SvgIcon {...props} viewBox="0 0 38 38">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="7.511 7.747 22.204 22.204">
      <g id="IG">
        <g
          id="_3rv5UQ.tif"
          data-name="3rv5UQ.tif"
          transform="translate(7.511 7.751)"
        >
          <g id="Group_634" data-name="Group 634" transform="translate(0 0)">
            <g id="Group_633" data-name="Group 633">
              <path
                id="Path_449"
                data-name="Path 449"
                d="M-325.479,2670.2h-10.84c-.227-.036-.457-.06-.68-.11a6.05,6.05,0,0,1-4.819-4.574c-.09-.324-.123-.664-.182-1v-10.84c.036-.227.06-.457.11-.68a6.05,6.05,0,0,1,4.574-4.818c.324-.09.664-.123,1-.182h10.84c.227.036.457.06.68.11a6.049,6.049,0,0,1,4.818,4.575c.09.324.123.664.182,1v10.84c-.036.227-.06.457-.11.68a6.051,6.051,0,0,1-4.574,4.819C-324.807,2670.11-325.147,2670.143-325.479,2670.2Zm-5.442-1.968q2.417,0,4.834,0a4.422,4.422,0,0,0,1.492-.224,4.154,4.154,0,0,0,2.828-4.069c0-3.223.01-6.445-.009-9.668a4.761,4.761,0,0,0-.224-1.466,4.1,4.1,0,0,0-4.054-2.836c-3.23,0-6.46-.01-9.689.009a4.693,4.693,0,0,0-1.445.221,4.1,4.1,0,0,0-2.84,4.029q-.012,4.878,0,9.754a4.1,4.1,0,0,0,1.753,3.475,4.165,4.165,0,0,0,2.544.776Q-333.327,2668.23-330.921,2668.233Z"
                transform="translate(342 -2648)"
              />
              <path
                id="Path_450"
                data-name="Path 450"
                d="M-206.5,2777.772a5.746,5.746,0,0,1-5.724,5.724,5.745,5.745,0,0,1-5.719-5.729,5.745,5.745,0,0,1,5.713-5.713A5.746,5.746,0,0,1-206.5,2777.772Zm-1.974.007a3.752,3.752,0,0,0-3.744-3.752,3.752,3.752,0,0,0-3.752,3.744,3.752,3.752,0,0,0,3.743,3.752A3.753,3.753,0,0,0-208.478,2777.779Z"
                transform="translate(223.326 -2766.674)"
              />
              <path
                id="Path_451"
                data-name="Path 451"
                d="M19.62,2733.727a1.438,1.438,0,1,1-1.476,1.416A1.446,1.446,0,0,1,19.62,2733.727Z"
                transform="translate(-2.526 -2730.01)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
)

export default InstagramIcon
